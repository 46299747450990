/* 
// 7. Why Choose Us
*/

.wc-box {
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    .wc-box-icon {
        font-size: 30px;
        height: 60px;
        width: 60px;
        line-height: 60px;
        border-radius: 50%;
        margin: 0 auto;
    }

    &:hover {
        transform: translateY(-6px);
        .wc-box-icon {
            background: rgba($white, 0.12) !important;
            color: $white !important;
            box-shadow: 0 0 0 7px rgba($white, 0.04);
        }
        .wc-title {
            color: $white;
        }
        .wc-subtitle {
            color: rgba($white, 0.5) !important;
        }

    }
}
.wc-box, .wc-box-icon, .wc-title, .wc-subtitle {
    transition: all 0.5s;
}

@each $color, $value in $theme-colors {
    .wc-box {
        &.wc-box-#{$color} {
            .wc-box-icon {
                background: rgba($value, 0.12);
                color: #{$value};
            }
            &:hover {
                background: #{$value};
                box-shadow: 0 5px 18px rgba($value, 0.4);
            }
        } 
    }
}